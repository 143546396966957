import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useFormik } from "formik"

const dataForm=[
    {
        name: "mombres",
        label: "nombres",
        type: "text",
        value: "",
    },
    {
        name: "apellidos",
        label: "nombres",
        type: "text",
        value: ""
    },
    {
        name: "ci",
        type: "text",
        label: "nombres",
        value: ""
    },
    {
        name: "genero",
        type: "select",
        label: "nombres",
        value: "",
        options: [
            {key:"1",option:"masculino"}
        ]
    }
]
const FormularioIp=({ dataForm, formSubmit})=>{
    const formData = dataForm.reduce((result, field) => {
        result[field.name] = field.value;
        return result;
    }, {});
    const formik = useFormik({
        initialValues: formData,
        onSubmit:(values)=>{
            formSubmit(values)
            formik.setValues(formData)
        }
    })
    
    return(
        <form onSubmit={formik.handleSubmit}>
            {dataForm.map((row,key)=> !row.hidden &&
            <div key={key}>
                {
                row.type === "select"?
                <div>
            <FormControl component={row.name} className="" fullWidth size="small">         
            <InputLabel>{row.label}</InputLabel>     
              <Select           
                labelId={row.name}
                id={row.name}
                name={row.name}
                label={row.label}
                value={formik.values[row.name]}
                onChange={formik.handleChange}
                sx={{ my: 1 }}
                size="small"               
              >
                 <MenuItem value="">Seleccionar...</MenuItem>
                 {
                    row.options.map((val,key)=>
                    <MenuItem value={val.key}>{val.option}</MenuItem>
                    )
                 }
                
              </Select>     
            </FormControl> 
                </div>
                :
                <TextField
                value={formik.values[row.name]}
                onChange={formik.handleChange}
                name={row.name}
                fullWidth
                sx={{ my: 1 }}
                label={row.label}
                error={formik.touched[row.name] && Boolean(formik.errors[row.name])}
                helperText={formik.touched[row.name] && formik.errors[row.name]}
                variant="outlined"
                size="small"
                type={row.type}  
            />
            }
            </div>
            )}
            <Button type="submit" size="small" variant="contained">Registrar</Button>
        </form>
    )
}
export default FormularioIp
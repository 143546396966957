import API from "../../axios/api"

export const getCompanies = async ()=>{
    const {data} = await API.get('api/companies/all')    
    return data
}
export const getAllCompanies = async ()=>{
    const {data} = await API.get('api/companies/get-all')    
    return data
}
export const getSucursales = async (val)=>{
    const {data} = await API.post('api/companies/sucursal',val)    
    return data
}
export const getSaleUsers = async ()=>{
    const {data} = await API.get('api/companies/sale-users')    
    return data
}
export const generateCUIS = async (val)=>{
    const {data} = await API.post('api/factura/cuis',val)    
    return data
}
export const getPuntos = async (val)=>{
    const {data} = await API.post('api/companies/puntos',val)    
    return data
}
export const getLogs = async (page)=>{
    const {data} = await API.get('api/get/logs?page='+page)    
    return data
}
export const getLog = async (val,page)=>{
    const {data} = await API.post('api/get/logs?page='+page,val)    
    return data
}
export const getBankCompanie = async ()=>{
    const {data} = await API.get('api/bank/list')    
    return data
}
export const setBankCompanie = async (val)=>{
    const {data} = await API.post('api/bank/register',val)    
    return data
}

export const setBranchCompany = async (val)=>{
    const {data} = await API.post('api/parametro/empresa/new',val)    
    return data
}
export const getBranchCompany = async ()=>{
    const {data} = await API.get('api/parametro/empresa',)    
    return data
}


import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { getAllCompanies } from "../../../services/config/configCompanies";
import { filter, find, size } from "lodash";
import { getCoeficienteActivos, setActivosList } from "../../../services/conta";
import * as yup from "yup";
import { listApiService } from "../../../services/crudServices";

const validationSchema = yup.object({
  company_id: yup.string().required("Ingrese este campo"),
  branch_office_id: yup.string().required("Ingrese este campo"),
  ubicacion: yup.string().required("Ingrese este campo"),
  cuadro_activos_fijos_id: yup.string().required("Ingrese este campo"),
  code: yup.string().required("Ingrese este campo"),
  name: yup.string().required("Ingrese este campo"),
  current_state: yup.string().required("Ingrese este campo"),
  comprobante: yup.string().required("Ingrese este campo"),
  importe: yup.string().required("Ingrese este campo"),
  fecha_registro: yup.string().required("Ingrese este campo"),
  detalle: yup.string().required("Ingrese este campo"),
});
const NewActivo = ({ closeModal }) => {
  const [companies, setCompanies] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [cuadroActivos, setCuadroActivos] = useState([]);
  const [unidad,setUnidad]= useState([])
  const [ciudad,setCiudad] = useState([])
  const [edificio,setEdificio] = useState([])
  const [oficina,setOficina] = useState([])
  const formik = useFormik({
    initialValues: {
      company_id:"",
      z_company_branch_id:"",
      unidad_id:"",
      ciudad_id:"",
      oficina_id:"",
      division_id:"",
      nro_alta:"",
      tipo_alta:"",
      fecha_alta:"",
      fecha_baja:"",
      ufv:"",
      tipo_cambio:"",
      moneda:"",
      valor_inicial:"",
      dep_acumulada:"",
      valor_recidual:"",
      tipo_bien_id:"",
      estado_bien:"",
      placa:"",
      descripcion:"",
      responsable:"",
      autorizado_por:"",
      barcode:"",
      motivo_baja:"",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setActivosList(values).then((res) => {
        closeModal();
      });
    },
  });
  const getCompanies = () => {
    getAllCompanies().then((res) => {
      setCompanies(res);
    });
  };
  const getCuadroActivos = () => {
    getCoeficienteActivos().then((res) => {
      setCuadroActivos(res);
    });
  };
  const loadUnidad=()=>{
    listApiService('unidad').then(res=>{
        setUnidad(res)
    })
}
  useMemo(() => {
    getCompanies();
    getCuadroActivos();
    loadUnidad()
  }, []);
  const handleChangeCompany = (e) => {
    formik.setFieldValue("company_id", e.target.value);
    const branchs = find(companies, (o) => {
      return o.id === e.target.value;
    });
    setBranchOffices(branchs?.branch_offices);
  };
  const handleChangeUnidad=(e)=>{
    formik.setFieldValue("unidad_id", e.target.value);
    const unidadSe = find(unidad, (o) => {
      return o.id === e.target.value;
    });
    setCiudad(unidadSe?.ciudad);
  }  
  const handleChangeCiudad=(e)=>{
    formik.setFieldValue("ciudad_id", e.target.value);
    const ciudadOf = find(ciudad, (o) => {
      return o.id === e.target.value;
    });
    setEdificio(ciudadOf?.edificio);
  }
  const handleChangeEdificio=(e)=>{
    formik.setFieldValue("edificio_id", e.target.value);
    const edifOf = find(edificio, (o) => {
      return o.id === e.target.value;
    });
    setOficina(edifOf?.oficina);
  }
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="md:grid grid-cols-2 gap-2">
          <FormControl fullWidth>
            <InputLabel id="company_id">Empresa</InputLabel>
            <Select
              labelId="empresa"
              id="company_id"
              name="company_id"
              label="Empresa"
              value={formik.values.company_id}
              onChange={handleChangeCompany}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.company_id && Boolean(formik.errors.company_id)
              }
            >
              <MenuItem value="all">todos</MenuItem>
              {companies.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.company_id && formik.errors.company_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="branch_office_id">Sucursal</InputLabel>
            <Select
              labelId="empresa"
              id="branch_office_id"
              disabled={size(branchOffices) === 0}
              name="branch_office_id"
              label="Sucursal"
              value={formik.values.branch_office_id}
              onChange={formik.handleChange}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.branch_office_id &&
                Boolean(formik.errors.branch_office_id)
              }
            >
              <MenuItem value="all">todos</MenuItem>
              {branchOffices.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.branch_office_id &&
                formik.errors.branch_office_id}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="unidad_id">Unidad</InputLabel>
            <Select
              labelId="unidad_id"
              id="unidad_id"              
              name="unidad_id"
              label="Sucursal"
              value={formik.values.unidad_id}
              onChange={handleChangeUnidad}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.unidad_id &&
                Boolean(formik.errors.unidad_id)
              }
            >
              <MenuItem value="all">todos</MenuItem>
              {unidad.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.unidad}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.unidad_id &&
                formik.errors.unidad_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="ciudad_id">Ciudad</InputLabel>
            <Select
              labelId="ciudad_id"
              id="ciudad_id"              
              name="ciudad_id"
              label="Ciudad"
              value={formik.values.ciudad_id}
              onChange={handleChangeCiudad}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.ciudad_id &&
                Boolean(formik.errors.ciudad_id)
              }
            >
              <MenuItem value="all">todos</MenuItem>
              {ciudad.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.ciudad}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.ciudad_id &&
                formik.errors.ciudad_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="edificio_id">Edificio</InputLabel>
            <Select
              labelId="edificio_id"
              id="edificio_id"              
              name="edificio_id"
              label="Edificio"
              value={formik.values.edificio_id}
              onChange={handleChangeEdificio}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.edificio_id &&
                Boolean(formik.errors.edificio_id)
              }
            >
              <MenuItem value="all">todos</MenuItem>
              {ciudad.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.ciudad}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.edificio_id &&
                formik.errors.edificio_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="oficina_id">Oficina</InputLabel>
            <Select
              labelId="oficina_id"
              id="oficina_id"              
              name="oficina_id"
              label="Oficina"
              value={formik.values.oficina_id}
              onChange={formik.handleChange}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.oficina_id &&
                Boolean(formik.errors.oficina_id)
              }
            >
              <MenuItem value="all">todos</MenuItem>
              {ciudad.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.ciudad}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.oficina_id &&
                formik.errors.oficina_id}
            </FormHelperText>
          </FormControl>

          <TextField
            value={formik.values.ubicacion}
            onChange={formik.handleChange}
            className="col-span-2"
            name="ubicacion"
            fullWidth
            sx={{ my: 1 }}
            label="Ubicacion"
            error={formik.touched.ubicacion && Boolean(formik.errors.ubicacion)}
            helperText={formik.touched.ubicacion && formik.errors.ubicacion}
            variant="outlined"
            size="small"
          />
          <FormControl fullWidth>
            <InputLabel id="cuadro_activos_fijos_id">
              Categoria (DS 24051)
            </InputLabel>
            <Select
              labelId="cuadro_activos_fijos_id"
              id="cuadro_activos_fijos_id"
              name="cuadro_activos_fijos_id"
              label="Categoria"
              value={formik.values.cuadro_activos_fijos_id}
              onChange={formik.handleChange}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.cuadro_activos_fijos_id &&
                Boolean(formik.errors.cuadro_activos_fijos_id)
              }
            >
              <MenuItem value="all">todos</MenuItem>
              {cuadroActivos.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.descripcion}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.cuadro_activos_fijos_id &&
                formik.errors.cuadro_activos_fijos_id}
            </FormHelperText>
          </FormControl>
          <TextField
            value={formik.values.code}
            onChange={formik.handleChange}
            name="code"
            fullWidth
            sx={{ my: 1 }}
            label="Codigo"
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            variant="outlined"
            size="small"
          />
          <TextField
            value={formik.values.name}
            onChange={formik.handleChange}
            className="col-span-2"
            name="name"
            fullWidth
            sx={{ my: 1 }}
            label="Nombre del activo Fijo"
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant="outlined"
            size="small"
          />
          <FormControl fullWidth>
            <InputLabel id="current_state">Estado</InputLabel>
            <Select
              labelId="current_state"
              id="current_state"
              name="current_state"
              label="Estado"
              value={formik.values.current_state}
              onChange={formik.handleChange}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.current_state &&
                Boolean(formik.errors.current_state)
              }
            >
              <MenuItem value="">seleccionar</MenuItem>
              <MenuItem value="activo">activo</MenuItem>
              <MenuItem value="inactivo">inactivo</MenuItem>
            </Select>
            <FormHelperText color="error">
              {formik.touched.current_state && formik.errors.current_state}
            </FormHelperText>
          </FormControl>
          <TextField
            value={formik.values.comprobante}
            onChange={formik.handleChange}
            name="comprobante"
            fullWidth
            sx={{ my: 1 }}
            label="Comprobante"
            error={
              formik.touched.comprobante && Boolean(formik.errors.comprobante)
            }
            helperText={formik.touched.comprobante && formik.errors.comprobante}
            variant="outlined"
            size="small"
          />
          <TextField
            value={formik.values.importe}
            onChange={formik.handleChange}
            name="importe"
            fullWidth
            sx={{ my: 1 }}
            label="Importe Bs"
            error={formik.touched.importe && Boolean(formik.errors.importe)}
            helperText={formik.touched.importe && formik.errors.importe}
            variant="outlined"
            type="number"
            size="small"
          />
          <TextField
            value={formik.values.fecha_registro}
            onChange={formik.handleChange}
            name="fecha_registro"
            fullWidth
            sx={{ my: 1 }}
            label="Fecha de registro"
            error={
              formik.touched.fecha_registro &&
              Boolean(formik.errors.fecha_registro)
            }
            helperText={
              formik.touched.fecha_registro && formik.errors.fecha_registro
            }
            variant="outlined"
            type="date"
            size="small"
          />
          <TextField
            value={formik.values.detalle}
            onChange={formik.handleChange}
            name="detalle"
            className="col-span-2"
            fullWidth
            sx={{ my: 1 }}
            label="Detalle"
            error={formik.touched.detalle && Boolean(formik.errors.detalle)}
            helperText={formik.touched.detalle && formik.errors.detalle}
            variant="outlined"
            size="small"
          />
          <Button variant="contained" type="submit">
            Registrar
          </Button>
        </div>
      </form>
    </div>
  );
};
export default NewActivo;

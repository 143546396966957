import { Button, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select } from "@mui/material"
import Pagev1 from "../../../layout/Pagev1"
import { useEffect, useRef, useState } from "react"
import { getAllCompanies, getCompanies } from "../../../services/config/configCompanies"
import { useFormik } from "formik"
import ModalComponent from "../../../components/ModalComponent"
import NewActivo from "./NewActivo"
import { getActivosList } from "../../../services/conta"
import { fNumber } from "../../../utils/formatNumber"
import { dateConfig, dateConfigBirth } from "../../../utils/dateconfig"
import { size, sumBy } from "lodash"
import { useReactToPrint } from "react-to-print"
import FormularioIp from "../../../components/FormularioIp"

const Activos = () => {
    const [companies,setCompanies] = useState([])
    const [data,setData] = useState([])
    
    const componentPrint = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentPrint.current,
      });
    const formik = useFormik({
        initialValues:{
            empresa:"all"
        },
        onSubmit:(values)=>{
            getActivosList(values).then(res=>{
                setData(res)
            })
        }
    })
    const dataForm = [
        {}
    ]
    const newActivo=(val)=>{

    }

    useEffect(()=>{
        getAllCompanies().then(res=>{
            setCompanies(res)
        })
    },[])
    
  return (
    <Pagev1 appBar={true} title="Activos fijos">
        <Paper className="p-3">
            <form onSubmit={formik.handleSubmit}>
                <div className="inline-flex gap-2">
                <FormControl >
                <InputLabel id="empresa">Empresa</InputLabel>
                <Select
                  labelId="empresa"
                  id="empresa"
                  name="empresa"
                  label="Empresa"
                  value={formik.values.empresa}
                  onChange={formik.handleChange}
                  sx={{ my: 1 }}
                  size="small"
                  error={
                    formik.touched.empresa && Boolean(formik.errors.empresa)
                  }
                >
                  <MenuItem value="all">todos</MenuItem>
                  {companies.map((row, key) => (
                    <MenuItem key={key} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText color="error">
                  {formik.touched.empresa && formik.errors.empresa}
                </FormHelperText>
              </FormControl>
                <div className="mt-2">
                <Button variant="contained" type="submit" >Generar</Button>
                </div>
                <div className="mt-2">
                <Button variant="contained" type="button" onClick={handlePrint} >Imprimir</Button>
                </div>
                <div className="mt-2">
                    <ModalComponent title="Registro de activo" btn="Nuevo" variant="contained">
                        <NewActivo /> 
                    </ModalComponent>                
                </div>
                </div>
            </form>
        </Paper>
        <Paper className="p-3 mt-3">
                {
                    size(data) > 0 ?
            <div ref={componentPrint}>
                <p className="font-xl text-center font-bold uppercase">Inventario de activos Fijos</p>
                <p className="font-xl text-center font-bold uppercase">{formik.values.empresa === "all"?"Todas las Empresas":data[0].company?.name}</p>
                    <table className="table-print">
                    <thead>
                        <tr>
                            <th>nro</th>
                            <th>codigo</th>
                            <th>nombre del activo fijo</th>
                            <th>estado</th>
                            <th>comprobante</th>
                            <th>importe</th>
                            <th>fecha de ingreso</th>
                            <th>categoria <Divider/> (DS 24051)</th>
                            <th>años de vida util</th>
                            <th>depreciacion %</th>
                            <th>detalles</th>
                            {formik.values.empresa === "all" && <th>empresa</th>}
                            <th>Sucursal</th>
                            <th>ubicacion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((row,key)=>
                           <tr key={key}>
                                <td>{key+1}</td>
                                <td>{row.code}</td>
                                <td>{row.name}</td>
                                <td>{row.current_state}</td>
                                <td>{row.comprobante}</td>
                                <td className="text-right">{fNumber(row.importe)}</td>
                                <td>{dateConfigBirth(row.fecha_registro)}</td>
                                <td>{row.cuadro_activos_fijos.descripcion}</td>
                                <td className="text-right">{row.cuadro_activos_fijos.vida_util}</td>
                                <td className="text-right">{fNumber(row.cuadro_activos_fijos.coeficiente)}</td>
                                <td>{row.detalle}</td>
                                {formik.values.empresa === "all" && <td>{row.company.name}</td>}
                                <td>{row.f_branchoffice.name}</td>
                                <td>{row.ubicacion}</td>
                           </tr>                            
                            )
                        }
                        <tr>
                            <th colSpan={5} className="text-right">Total Bs.</th>
                            <td className="text-right">{fNumber(sumBy(data,o=>{
                                return parseFloat(o.importe)
                            }))}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            : <p className="font-semibold">No existen registros para la empresa seleccionada</p>
        }
        </Paper>
    </Pagev1>
  )
}
export default Activos
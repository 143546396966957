import { fInvoiceNumber, fNumber } from "./formatNumber"

export function dateConfig(date){
    return new Date(date).toLocaleString()
}
export function dateConfigTitle(date){
    
   const dates =  new Date(date + " 00:00:00")
    return formatShortDate(dates)
}
export function dateConfigBirth(date){
    
   const dates =  new Date(date)
    return formatShortDate(dates)
}
function formatShortDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  export function dateConfigNumber(number,date){
    
    const dates =  new Date(date)
     return fDataNumber(number,dates)
 }
function fDataNumber(number,date) {
    const numero = fInvoiceNumber(number);
    
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${numero}-${month}/${year}`;
  }
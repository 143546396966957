import {
  Fab,
  LinearProgress,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography,
} from "@mui/material";
import Box from '@mui/material/Box';
import { Fragment, useEffect, useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { Menu } from "react-pro-sidebar";
import { Sidebar } from "react-pro-sidebar";
import { SubMenu } from "react-pro-sidebar";
import { menuClasses } from "react-pro-sidebar";

import { MenuItem } from "react-pro-sidebar";
import Logo from "../components/Logo";
import AdminNavbar from "./NavBar";
import Iconify from "../components/Iconify";
import { Link, matchPath, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { groupBy, size } from "lodash";
import GroupIcon from "@mui/icons-material/Group";
import ChatIcon from "@mui/icons-material/Chat";
import ModalComponent from "../components/ModalComponent"
import { ChatContent } from "../sections/chat";
//import socketIOClient from "socket.io-client"
const getIcon = (name) => <Iconify icon={name} width={25} height={25} />;
const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const Pagev1 = ({ children, title = "", appBar = false }) => {
  const { toggleSidebar, collapseSidebar, collapsed } = useProSidebar();

  const [isRTL, setIsRTL] = useState(false);
  const [hasImage, setHasImage] = useState(true);
  const [theme, setTheme] = useState("dark");
  const userData = useSelector((state) => state.userData);
  const { pathname } = useLocation();
  const load = useSelector((state) => state.loading);
  const [groupedMenu, setGroupedMenu] = useState([]);
  //let socketio = socketIOClient("https://conta.samkabolivia.com")

  const match = (path) =>
    path ? !!matchPath({ path, strict: true }, pathname) : false;

  // handle on RTL change event
  const handleRTLChange = (e) => {
    setIsRTL(e.target.checked);
  };
  // handle on theme change event
  const handleThemeChange = (e) => {
    setTheme(e.target.checked ? "dark" : "light");
  };
  // handle on image change event
  const handleImageChange = (e) => {
    setHasImage(e.target.checked);
  };

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: ({ level, active, disabled }) => {
      // only apply styles on first level elements of the tree

      return {
        "&:hover": {
          backgroundColor: hexToRgba(
            themes[theme].menu.hover.backgroundColor,
            hasImage ? 0.8 : 1
          ),
          color: themes[theme].menu.hover.color,
        },
        color: disabled ? "#070c2d" : themes[theme].menu.hover.color,
        backgroundColor: active
          ? hexToRgba(
              themes[theme].menu.hover.backgroundColor,
              hasImage ? 0.8 : 1
            )
          : undefined,
      };
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  useEffect(() => {
    setGroupedMenu(groupBy(userData.menu, "group"));
  }, [userData]);

  /*    const notificationSk =(title,message)=>{
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          new Notification(title, { body: message });
        }
      });
    }
  }  */

  /*    useEffect(()=>{
    Notification.requestPermission();
  },[])  */

  /* useEffect(()=>{
    socketio.on('alertsk',alert =>{ 
       notificationSk(alert.title,alert.message)
    })
},[]) */

  return (
    <div style={{ display: "flex", height: "100%" }} className="w-full">
      <Sidebar
        image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
        rtl={isRTL}
        breakPoint="lg"
        backgroundColor={hexToRgba(
          themes[theme].sidebar.backgroundColor,
          hasImage ? 0.9 : 1
        )}
        rootStyles={{
          color: themes[theme].sidebar.color,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100vh" }}
        >
          <div className="">
            <div className="p-2 max:h-8 w-full mx-auto bg-blue-100">
              <div className="mx-auto">
                <Logo />
              </div>
              <div style={{ padding: "0 24px", marginBottom: "8px" }}>
                <Typography
                  variant="body2"
                  fontWeight={600}
                  className="text-primary text-center text-2xl"
                  style={{
                    opacity: collapsed ? 0 : 0.7,
                    letterSpacing: "0.5px",
                  }}
                >
                  {userData.store_name}
                </Typography>
              </div>
            </div>
            <div style={{ flex: 1, marginBottom: "32px" }}>
              <Menu
                menuItemStyles={menuItemStyles}
                className="text-xs capitalize"
              >
                {Object.keys(groupedMenu).map((row, key) => {
                  const menuItems = groupedMenu[row].map((val, index) => (
                    <MenuItem
                      key={index}
                      active={match(val.path)}
                      icon={getIcon(val.icon)}
                      component={<Link to={val.path} />}
                    >
                      <p className="capitalize"> {val.title} </p>
                    </MenuItem>
                  ));

                  if (row === "*") {
                    return menuItems;
                  } else if (menuItems.length > 0) {
                    return (
                      <SubMenu
                        label={row}
                        key={key + "-main"}
                        icon={getIcon(groupedMenu[row][0]["group_icon"])}
                      >
                        {menuItems}
                      </SubMenu>
                    );
                  } else {
                    return null;
                  }
                })}
              </Menu>
            </div>
          </div>

          <div>
            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                icon={getIcon("vscode-icons:file-type-ember")}
                component={<Link to='/ticket' />}
              >
                Ticket
              </MenuItem>
              <MenuItem
                icon={getIcon("vscode-icons:file-type-ember")}
                onClick={() => collapseSidebar(!collapsed)}
              >
                encoger
              </MenuItem>
            </Menu>
            <p className="text-xs">T v0.3.1</p>
          </div>
        </div>
      </Sidebar>
      <div className={collapsed ? "md:ml-20 w-full" : "md:ml-60 w-full"}>
        <AdminNavbar
          title={title}
          className="fixed"
          toggleSidebar={toggleSidebar}
        />
        <div className="p-3 ml-2">{children}</div>
      </div>
      {/*  <Box className="fixed bottom-4 right-4" >
   
        <ModalComponent isButton={true} btn={ <ChatIcon />} fullScreenx={false} title={"Chat Empresarial"}>
      <ChatContent/>        
        </ModalComponent>
      </Box> */ }
    </div>
  );
};

export default Pagev1;

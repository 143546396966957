import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Fab,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from '@mui/icons-material/Print';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

import { useReactToPrint } from "react-to-print";
import { Children, cloneElement, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoad } from "../redux/features/config/eventButton";

const ModalComponent = ({children,btn,title,doc=null,id=null,reload=null,variant=null,fullScreenx=null,isButton=false, color="primary"}) => {
    const [open,setOpen] = useState(false)
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const componentRef = useRef();
    const handleClose = () => {
      setOpen(false);
      reload();
    };
    const handlePrint = useReactToPrint({
      content: ()=>componentRef.current,
    })
    const handleChangePrint=()=>{
      dispatch(setLoad())
    }
    
  return (
    <div>
       {isButton ?<Fab size="medium" color="primary" aria-label="add" onClick={()=>setOpen(true)}>
      {btn}
    </Fab>:
        <Button size="small" color={color} variant={variant} onClick={()=>setOpen(true)}>{btn}</Button>}
        <Dialog
      fullScreen={fullScreenx || fullScreen}
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="xl"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" className="uppercase">
            {title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleChangePrint}
            aria-label="close"
            className="mr-2"
          >
            <CompareArrowsIcon />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handlePrint}
          >
            <PrintIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent ref={componentRef} className={fullScreenx?"":"max-w-5xl"}>

       {open&& <div className="w-full">
        {
            Children.map(children,child=>cloneElement(child,{
                closeModal:handleClose
            }))
          }
        </div>}
      </DialogContent>
    </Dialog>
    </div>
  )
}
export default ModalComponent